import {Col, Row} from "react-bootstrap";
import axios from "../../lib/axios";
import {setUsers} from "../reducers/chiSiamoSlicer";
import {useDispatch, useStore} from "react-redux";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {ChallengeForm} from "./ChallengeForm";

export const Challenge = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [renderArray, setRenderArray] = useState([]);
    const [difficulty, setDifficulty] = useState("");
    const [disabledGenerate, setDisableGenerate] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState("");

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});

        }
        getChallenge();
    }, [usersReducer]);

    const handleDifficultyChange = (e) =>{
        setDifficulty(e.target.value);
    }

    const generateChallenge = () =>{

        setLoadingStatus("Generazione sfida in corso, attendi...");
        setDisableGenerate("disabled");

        if(difficulty !== "") {

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.post("/start/challenge", {
                    userId: users.userId,
                    command: difficulty,
                }).then((response) => {

                    setLoadingStatus("");
                    setDisableGenerate(false);

                    if(response.data.action === 0){
                        getChallenge(true);
                   }
                   else if(response.data.action === 4 || response.data.action === 3){
                       alert(response.data.message);
                   }

                }).catch((error) => {
                    if(error.response.status == 401){
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    }
                    else{
                        alert(error.response.data.message);
                    }
                });

            });
        }
        else{
            alert("Compila prima i dati necessari");
        }
    }

    const getChallenge = (emptyHistory = false) =>{

        if(emptyHistory){
            setRenderArray([]);
        }

        axios.get("/sanctum/csrf-cookie").then((response) => {

            axios.get("/get/challenge", {
                params:{
                    userId:users.userId,
                }
            }).then((response) => {

                response.data.challenges.map((item, i) => {

                    setRenderArray( (prevState) => [ ...prevState,
                        <ChallengeForm text={item.challenge} input={item.input} output={item.output} code={item.code} id={item.id} difficulty={item.difficulty}></ChallengeForm>

                    ]);

                });

            }).catch((error) => {
                if (error.response.status == 401) {

                    axios.post("/logout").then((response) => {
                        dispatch(setUsers({}));
                        navigate("/login");
                    }).catch((error)=>{});

                } else {
                    alert(error.response.data.message);
                }
            });

        });
    }

    let renderMap = renderArray.map((item, i) => {
        return item;
    });

    return (
        <>
            <Row lg={12} className="justify-content-center">
                <Col lg={6} sm={12}>
                    <div className="login-wrapper2">
                        <select
                            id="tech"
                            className="login-flip-card__input2"
                            name="tech"
                            onChange={(e) => handleDifficultyChange(e)}
                            value={difficulty}
                        >
                            <option value="" selected="true">Seleziona una difficoltà per la sfida</option>
                            <option value="facile">Facile</option>
                            <option value="media">Media</option>
                            <option value="difficile">Difficile</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <Row lg={6} className="justify-content-center">
                <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}>
                    <button disabled={disabledGenerate} onClick={() => generateChallenge()} className="prosegui-button">Genera</button>
                </Col>
            </Row>
            <div className="mt-2">
                {loadingStatus}
            </div>
            <div className="mt-3">
                <span>Puoi generare fino a 3 sfide giornaliere.</span>
            <hr/>
            </div>
            {renderMap}
        </>

    );


}