import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {configureStore, combineReducers} from "@reduxjs/toolkit";
import {Provider} from "react-redux";
import {userSliceReducer} from "./pages/reducers/chiSiamoSlicer";

const reducers = combineReducers({userSliceReducer});

export const store = configureStore( {
    reducer : reducers,
    middleware : (getDefaultMiddleware) => getDefaultMiddleware(),
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
    <App />
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
