import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";
import {useDispatch, useStore} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "../lib/axios";
import {setUsers} from "./reducers/chiSiamoSlicer";
import {InterviewButton} from "./widgets/InterviewButton";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

export const Interview = () =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const users = actualStore.userSliceReducer.users ?? null;
    const usersReducer =  actualStore.userSliceReducer;
    const [interviews, setInterviews] = useState([]);
    const [username, setUsername] = useState("");
    const [tech, setTech] = useState("");
    const [results, setResults] = useState("");

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});
        }

        getInterviews();

    }, [usersReducer]);

    const getInterviews = (event = null) =>{
        setInterviews([]);

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.get("/get/interviews", {
                   params:{ userId:users.userId,
                            results:results,
                            tech: tech,
                            username:username
                   }
                }).then((response) => {

                    if(response.data.interviews.length !==0) {
                        response.data.interviews.map((item, i) => {

                            setInterviews((prevState) => [...prevState,

                                <Row lg={12} sm={12} className="justify-content-center">
                                    <Col lg={6} sm={12}>
                                        <InterviewButton key={i}
                                                         text={item.tmpUserName + " " + item.tech + " Voto: " + item.avg + "%"}
                                                         content={JSON.parse(item.interview)}></InterviewButton>
                                    </Col>
                                </Row>
                            ]);

                        });
                    }
                    else{
                        setInterviews(["Nessun colloquio presente"]);
                    }

                }).catch((error) => {
                    if (error.response.status == 401) {

                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});

                    } else {
                        alert(error.response.data.message);
                    }
                });

            });
    }

    let interviewsMap = interviews.map((item, i) => {
        return item;
    });

    let handleUsernameChange = (e) =>{
        setUsername(e.target.value);
    }

    let handleTechChange = (e) =>{
        setTech(e.target.value);
    }

    let handleResultsChange = (e) =>{
        setResults(e.target.value);
    }

        return (
            <>
            <Header></Header>
                <Container className="page-container" fluid>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">

                                <input
                                    id = "username"
                                    className="login-flip-card__input2"
                                    name="username"
                                    placeholder="Cerca nome candidato"
                                    type="text"
                                    value = {username}
                                    onChange={(e) => handleUsernameChange(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">
                            <input
                                    id = "tech"
                                    className="login-flip-card__input2"
                                    name="tech"
                                    placeholder="Cerca tech e seniority"
                                    type="text"
                                    value = {tech}
                                    onChange={(e) => handleTechChange(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">
                                <input
                                    id = "results"
                                    className="login-flip-card__input2"
                                    name="results"
                                    placeholder="Numero di risultati"
                                    type="text"
                                    value = {results}
                                    onChange={(e) => handleResultsChange(e)}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">
                            <button className="prosegui-button" onClick={()=>getInterviews()}>Cerca</button>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    {interviewsMap}
                </Container>
            <Footer></Footer>
            </>
        );
}