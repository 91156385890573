import {useState} from "react";
import {Toast} from "react-bootstrap";
import DiffViewer from "react-diff-viewer";
import {CodeReview} from "./CodeReview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

export const CodeReviewButton = (props) =>{
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const toggleShow = () => setShow(!show);
    const toggleShow2 = () => setShow2(!show2);

    const copyToClipBoard = (e, content) => {
        navigator.clipboard.writeText(content);
        alert("Codice copiato.");
    }

    return (
        <>
        <button onClick={toggleShow} className="cube-btn2 cube cube-hover" type="button">
            <div className="bg-top">
                <div className="bg-inner" />
            </div>
            <div className="bg-right">
                <div className="bg-inner" />
            </div>
            <div className="bg">
                <div className="bg-inner" />
            </div>
            <div className="text">{props.text} ({props.answers.length}) {props.notes}</div>
        </button>
            <Toast show={show} onClose={toggleShow}>
                <Toast.Header>
                    <strong className="me-auto">{props.text} ({props.answers.length})</strong>
                </Toast.Header>
                <Toast.Body>
                    <button className="prosegui-button mb-2" style={{width:250}} onClick={toggleShow2}>Nuova Revisione</button>
                    <Toast show={show2} onClose={toggleShow2}>
                        <Toast.Header>
                            <strong className="me-auto">Inserisci nuova revisione</strong>
                        </Toast.Header>
                        <Toast.Body>
                           <CodeReview codeReviewId={props.codeReviewId} update={1} setUpdateNeeded = {props.setUpdateNeeded} actualUpdateValue={props.actualUpdateValue}></CodeReview>
                        </Toast.Body>
                    </Toast>
                    <hr/>
                    <span><FontAwesomeIcon style={{cursor:"pointer"}} onClick={(e) => copyToClipBoard(e, props.content)} size="2x" icon={faCopy}></FontAwesomeIcon></span>
                    <DiffViewer oldValue={props.content} useDarkTheme={true} splitView={false}></DiffViewer>
                    <hr/>
                    {props.answers.map((item, i) => {

                        return(
                            <>
                                <h2>Revisione {i+1} by {item.username}</h2>
                                {item.notes}<hr/>
                                <DiffViewer oldValue={props.content} newValue={item.content} useDarkTheme={true} splitView={true}></DiffViewer>
                            </>);

                    })}
                </Toast.Body>
            </Toast>
        </>
    );
}