import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Pricing} from "./pages/Pricing";
import {Terms} from "./pages/Terms";
import {Login} from "./pages/Login";
import {Dashboard} from "./pages/Dashboard";
import {Profile} from "./pages/Profile";
import {Interview} from "./pages/Interview";
import {CodeReviewList} from "./pages/CodeReviewList";

function App() {

  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/pricing" element={ <Pricing /> } />
            <Route path="/terms" element={ <Terms /> } />
            <Route path="/login" element={ <Login /> } />
            <Route path="/dashboard" element={ <Dashboard /> } />
            <Route path="/profile" element={ <Profile /> } />
            <Route path="/interview" element={ <Interview /> } />
            <Route path="/codeReviewList" element={ <CodeReviewList /> } />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
