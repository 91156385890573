export const HomeCard = (props) => {

    return (
        <>
            <div className="home-card">
                <p className="home-card-title">{props.cardTitle}</p>
                <p className="home-card-body">
                    {props.cardBody}
                </p>
            </div>
        </>
    );

}