import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {ProfileCard} from "./widgets/ProfileCard";
import "./css/ChiSiamo.css";
import axios from "axios";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUsers} from "./reducers/chiSiamoSlicer";

export const Terms = () => {

    return (
        <>
        <Header></Header>
            <Container className="page-container" fluid>
                <Row lg={12}>
                    <Col lg={12}>
                       Pagina in costruzione.
                    </Col>
                </Row>
            </Container>
        <Footer></Footer>
        </>
    );
};
