import { createSlice } from '@reduxjs/toolkit'

const initialState = {"users": {}};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        }
    },
})

const { actions, reducer } = userSlice;

export const { setUsers } = actions;

export const userSliceReducer = reducer