export const DashboardButton = (props) =>{

    return (
        <button className="cube-btn cube cube-hover" type="button">
            <div className="bg-top">
                <div className="bg-inner" />
            </div>
            <div className="bg-right">
                <div className="bg-inner" />
            </div>
            <div className="bg">
                <div className="bg-inner" />
            </div>
            <div className="text">{props.text}</div>
        </button>
    );
}