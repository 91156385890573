import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";
import {useDispatch, useStore} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "../lib/axios";
import {setUsers} from "./reducers/chiSiamoSlicer";
import {InterviewButton} from "./widgets/InterviewButton";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {CodeReviewButton} from "./widgets/CodeReviewButton";

export const CodeReviewList = () =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const users = actualStore.userSliceReducer.users ?? null;
    const usersReducer =  actualStore.userSliceReducer;
    const [codeReviewList, setCodeReviewList] = useState([]);
    const [languageId, setLanguageId] = useState(null);
    const [results, setResults] = useState(null);
    const [userId, setUserId] = useState(null);
    const [languageList, setLanguageList] = useState([]);
    const [norevision, setNoRevision] = useState(0);
    const [updateNeeded, setUpdateNeeded] = useState(0);


    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});
        }

        getCodeReviewList();
        getLanguageList();

    }, [usersReducer,updateNeeded]);

    const getCodeReviewList = (event = null) =>{
        setCodeReviewList([]);

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.get("/get/code/review", {
                   params:{
                            userId:userId,
                            languageId:languageId,
                            results: results,
                            noRevision : norevision
                   }
                }).then((response) => {

                    if(response.data.length !==0) {
                        response.data.map((item, i) => {

                            setCodeReviewList((prevState) => [...prevState,

                                <Row lg={12} sm={12} className="justify-content-center">
                                    <Col lg={6} sm={12}>
                                        <CodeReviewButton key={i}
                                                         text={item.username + " " + item.languageLabel} content={item.content} notes={item.notes}
                                                         answers={item.answers} codeReviewId={item.id} setUpdateNeeded = {setUpdateNeeded} actualUpdateValue={updateNeeded}></CodeReviewButton>
                                    </Col>
                                </Row>
                            ]);

                        });
                    }
                    else{
                        setCodeReviewList(["Nessuna code review presente"]);
                    }

                }).catch((error) => {
                    if (error.response.status == 401) {

                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});

                    } else {
                        alert(error.response.data.message);
                    }
                });

            });
    }

    const getLanguageList = (event = null) =>{

        axios.get("/sanctum/csrf-cookie").then((response) => {

            axios.get("/get/language/list", {}).then((response) => {

                if(response.data.length !==0) {
                   setLanguageList(response.data.languages);
                }

            }).catch((error) => {
                if (error.response.status == 401) {

                    axios.post("/logout").then((response) => {
                        dispatch(setUsers({}));
                        navigate("/login");
                    }).catch((error)=>{});

                } else {
                    alert(error.response.data.message);
                }
            });

        });
    }

    let codeReviewListMap = codeReviewList.map((item, i) => {
        return item;
    });

    let handleLanguageIdChange = (e) =>{
        setLanguageId(e.target.value);
    }

    let handleUserIdChange = (e) =>{

        if(e.target.checked){
            setUserId(users.userId);
        }
        else {
            setUserId(null);
        }
    }

    let handleResultsChange = (e) =>{
        setResults(e.target.value);
    }

    let handleNoRevisionChange = (e) =>{

        if(e.target.checked){
            setNoRevision(1);
        }
        else{
            setNoRevision(0);
        }
    }

        return (
            <>
            <Header></Header>
                <Container className="page-container" fluid>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">
                            <select
                                    id = "tech"
                                    className="login-flip-card__input2"
                                    name="tech"
                                    onChange={(e) => handleLanguageIdChange(e)}
                                    value={languageId}
                                >
                                <option value={""}>Seleziona un linguaggio</option>
                                {languageList.map((item, i) => {
                                    return(<option value={item.id}>{item.description}</option>);
                                })}
                            </select>
                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">
                                <input
                                    id = "results"
                                    className="login-flip-card__input2"
                                    name="results"
                                    placeholder="Numero di risultati"
                                    type="text"
                                    value = {results}
                                    onChange={(e) => handleResultsChange(e)}
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={1} sm={12}>
                            <div className="login-wrapper2">
                                Solo proprie:
                                <input
                                    id = "userId"
                                    className="login-flip-card__input2"
                                    name="username"
                                    type="checkbox"
                                    title="Solo proprie code review"
                                    value = {userId}
                                    onClick={(e) => handleUserIdChange(e)}
                                />
                            </div>
                        </Col>
                        <Col lg={1} sm={12}>
                            <div className="login-wrapper2">
                                Senza revisioni:
                                <input
                                    id = "noresponse"
                                    className="login-flip-card__input2"
                                    name="noresponse"
                                    type="checkbox"
                                    title="Solo senza revisioni"
                                    value = {norevision}
                                    onClick={(e) => handleNoRevisionChange(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row lg={12} className="justify-content-center">
                        <Col lg={3} sm={12}>
                            <div className="login-wrapper2">
                            <button className="prosegui-button" onClick={()=>getCodeReviewList()}>Cerca</button>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    {codeReviewListMap}
                </Container>
            <Footer></Footer>
            </>
        );
}