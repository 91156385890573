import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";
import {ShellCard} from "./widgets/ShellCard";
import {HomeCard} from "./widgets/HomeCard";
import {useNavigate} from "react-router-dom";

const text = "Entra nel mondo della programmazione.";
const card1Title = "Scarsa Durata";
const card1Body = "Molti corsi durano pochi mesi ma con grande densità di argomenti, generando confusione.";
const card2Title = "Velocità di apprendimento non adatta a tutti";
const card2Body = "Ogni persona ha il suo tempo di apprendimento, ma spesso questo non viene preso in considerazione.";
const card3Title = "Costo eccessivo";
const card3Body = "Il costo di molti corsi varia tra i 1.500 e 6000 Euro, prezzi non proprio accessibili.";
const card4Title = "Percorsi personalizzati e roadmap chiare";
const card4Body = "Per noi la formazione è al centro. Questo significa che ogni developer avrà a disposizione materiale di alta qualità, roadmap e percorsi ben definiti, il tutto fruibile in base ai propri tempi.";
const card5Title = "Costo irrisorio e gamification";
const card5Body = "Ogni developer può fruire dei contenuti del portale al costo di una pizza al mese. Inoltre farai parte di una community di dev come te, che imparano e gareggiano per scalare la classifica, grazie a code challenge e sfide.";
const card6Title = "Placing presso le aziende e tool potenziati dall'AI";
const card6Body = "Devocracy ti supporta attivamente nel tuo percorso professionale, approfondendo tematiche ad hoc e permettendoti di entrare in contatto con le aziende.  Inoltre, potenti tool AI come il nostro HR tecnico ti permetteranno di avere sfide crescenti.";

export const Home = () =>{

    const navigate = useNavigate();

    const gotoPricing = ()=>{
        navigate("/pricing");
    }

    return (
        <>
        <Header></Header>
            <Container className="page-container" fluid>
                <Row lg={12}>
                   <Col lg={12} className="text-center">
                       <h1>Benvenuto su Devocracy! (SITO IN COSTRUZIONE)</h1>
                       <span className="home-paragraph">
                            <Row lg={12}>
                                <Col lg={2}></Col>
                                <Col lg={8}>
                                    <ShellCard text={text}></ShellCard>
                                </Col>
                                <Col lg={2}></Col>
                              </Row>
                       </span>
                   </Col>
                </Row>
                <Row lg={12} className="mt-5 text-center justify-content-center">
                    <h1 style={{marginTop:30}}>Cosa manca ai corsi tradizionali?</h1>
                    <Col lg={2} md={12} sm={12}>
                        <HomeCard imagePath={""} cardTitle={card1Title} cardBody={card1Body}></HomeCard>
                    </Col>
                    <Col lg={2} md={12} sm={12}>
                        <HomeCard imagePath={""} cardTitle={card2Title} cardBody={card2Body}></HomeCard>
                    </Col>
                    <Col lg={2} md={12} sm={12}>
                        <HomeCard imagePath={""} cardTitle={card3Title} cardBody={card3Body}></HomeCard>
                    </Col>
                </Row>
                <Row lg={12} className="mt-5 text-center justify-content-center">
                    <h1 style={{marginTop:30}}>Perché scegliere Devocracy?</h1>
                    <Col lg={2} md={12} sm={12}>
                        <HomeCard imagePath={""} cardTitle={card4Title} cardBody={card4Body}></HomeCard>
                    </Col>
                    <Col lg={2} md={12} sm={12}>
                        <HomeCard imagePath={""} cardTitle={card5Title} cardBody={card5Body}></HomeCard>
                    </Col>
                    <Col lg={2} md={12} sm={12}>
                        <HomeCard imagePath={""} cardTitle={card6Title} cardBody={card6Body}></HomeCard>
                    </Col>
                </Row>
                <Row lg={12} className="mt-5 text-center justify-content-center">

                    <Col lg={6} md={6} sm={12}>
                        <button className="prosegui-button" onClick={()=>gotoPricing()}>Scopri di più</button>
                    </Col>
                </Row>
            </Container>
        <Footer></Footer>
        </>
    );
}