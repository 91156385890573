import {Col, Row} from "react-bootstrap";
import axios from "../../lib/axios";
import {setUsers} from "../reducers/chiSiamoSlicer";
import {useDispatch, useStore} from "react-redux";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {ChallengeShell} from "./ChallengeShell";

export const ChallengeForm = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [code, setCode] = useState("");
    const [output, setOutput] = useState("");

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});
        }

        if(props.output && props.code !==""){
            setOutput(props.output);
        }

        if(props.code){
            setCode(props.code);
        }

    }, [usersReducer]);

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }

    const handleOutputChange = (e) => {
        setOutput(e.target.value);
    }

    const updateChallenge = () =>{

        if(output !== "" && code !== "") {

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.put("/update/challenge", {
                    userId: users.userId,
                    challengeId: props.id,
                    code : code,
                    output : output
                }).then((response) => {

                    if(!response.data.esito){
                        alert(response.data.message);
                    }
                    else{
                        alert("Risposta inviata con successo.");
                    }

                }).catch((error) => {
                    if(error.response.status == 401){
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    }
                    else{
                        alert(error.response.data.message);
                    }
                });

            });
        }
        else{
            alert("Compila prima i dati necessari");
        }
    }

    return (
        <>
            <Row lg={12} className="justify-content-center">
            <ChallengeShell difficulty={props.difficulty} id={props.id} text={props.text} input={props.input}></ChallengeShell>
            </Row>
            <Row lg={12} className="justify-content-center">
                <Col lg={6} sm={12}>
                    <div className="login-wrapper_textarea">
                    <textarea
                        id="code"
                        className="login-flip-card__input_textarea"
                        name="code"
                        placeholder="Inserisci codice in risposta"
                        onChange={(event) => {
                            handleCodeChange(event)
                        }}
                        value={code}
                    />
                    </div>
                </Col>
            </Row>
            <Row lg={12} className="justify-content-center">
                <Col lg={6} sm={12}>
                    <div className="login-wrapper_textarea">
                <input
                    id="output"
                    className="login-flip-card__input_textarea"
                    name="output"
                    readOnly={"true"}
                    placeholder="Inserisci output della sfida"
                    type="text"
                    onChange={(event) => {
                        handleOutputChange(event)
                    }}
                    value={output}
                />
                    </div>
                </Col>
            </Row>
            <Row lg={6} className="justify-content-center">
                <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}>
                    <button onClick={() => updateChallenge()} className="prosegui-button">Invia</button>
                </Col>
            </Row>
        </>

    );


}