import logo from '../assets/images/devocracy.png'; // with import
import { CDBSidebarFooter, CDBLink, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';

export const Footer = () => {

    return (
        <CDBSidebarFooter className="shadow footer">
            <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{width: '90%'}}>
                <CDBBox display="flex" justifyContent="between" className="flex-wrap">
                    <CDBBox>
                        <a href="/" className="d-flex align-items-center p-0 text-dark">
                            <img alt="logo" src={logo} width="120px"/>
                        </a>
                        <p className="my-3">
                        Con devocracy, la formazione per programmatori <br />ha una marcia in più.
                        </p>
                        <small className="text-center mt-5 text-warning">&copy; Lorenzo Casponi - 2024 - All Rights Reserved.</small>
                    </CDBBox>
                </CDBBox>
            </CDBBox>
        </CDBSidebarFooter>
    );
}