export const CorsoTab = (props) => {

    return (
        <div className="cr-unique-card">
            <div className="cr-background-overlay" />
            <div className="cr-card-content">
                <div className="cr-card-title">{props.courseTitle}</div>
                <div className="cr-card-description">{props.courseDescription}</div>
            </div>
        </div>

    );

}