export const ShellCard = (props) => {

return (
    <>
        <div className="shell-container">
            <div className="shell-container_terminal"></div>
            <div className="shell-terminal_toolbar">
                <div className="shell-butt">
                    <button className="btn shell-btn-color"></button>
                    <button className="btn"></button>
                    <button className="btn"></button>
                </div>
                <p className="shell-user">devocracy@admin: ~</p>
            </div>
            <div className="shell-terminal_body">
                <div className="shell-terminal_promt">
                    <span className="shell-terminal_user">devocracy@admin:</span>
                    <span className="shell-terminal_location">~</span>
                    <span className="shell-terminal_bling">$</span>
                    <span className="shell-terminal_user">{props.text}</span> <span className="shell-terminal_cursor"></span>
                </div>
            </div>
        </div>
    </>
);

}