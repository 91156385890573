import {Col, Row} from "react-bootstrap";
import axios from "../../lib/axios";
import {setUsers} from "../reducers/chiSiamoSlicer";
import {useDispatch, useStore} from "react-redux";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const GuestChoice = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [guestUsername, setGuestUsername] = useState("");
    const [guestEmail, setGuestEmail] = useState("");

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});
        }
    }, [usersReducer]);

    const handleUsernameChange = (e) =>{
        setGuestUsername(e.target.value);
    }

    const handleEmailChange = (e) =>{
        setGuestEmail(e.target.value);
    }

    const generateGuest = () =>{

        if(guestEmail !== "" && guestUsername !=="") {

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.post("/generate/guest/user", {
                    email: guestEmail,
                    userName: guestUsername,
                }).then((response) => {

                   if(response.data.esito === 1){
                       alert("Utenza candidato creata con successo, la password temporanea è 12345678");

                       setGuestEmail("");
                       setGuestUsername("");
                   }
                   else{
                       alert(response.data.message);
                   }

                }).catch((error) => {
                    if(error.response.status == 401){
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    }
                    else{
                        alert(error.response.data.message);
                    }
                });

            });
        }
        else{
            alert("Compila prima i dati del candidato");
        }
    }

    const deleteGuest = () =>{

        if(window.confirm("Sei sicuro di voler eliminare tutte le utenze guest?")) {
            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.delete("/delete/guest/user", {
                    params:{ companyUserId: users.userId}
                }).then((response) => {

                    if (response.data.esito === 1) {
                        alert("Utenze guest eliminate con successo.");

                        setGuestEmail("");
                        setGuestUsername("");
                    }

                }).catch((error) => {
                    if(error.response.status == 401){
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    }
                    else{
                        alert(error.response.data.message);
                    }
                });

            });
        }
    }

    return (
        <>
        <Row lg={12} className="justify-content-center">
            <Col lg={6} sm={12}>
                <div className="login-wrapper2">

                    <input
                        id = "username"
                        className="login-flip-card__input2"
                        name="username"
                        placeholder="Nome utente"
                        type="text"
                        value={guestUsername}
                        onChange={(event) => {handleUsernameChange(event)}}
                    />
                </div>
            </Col>
        </Row>
        <Row lg={12} className="justify-content-center">
            <Col lg={6} sm={12}>
                <div className="login-wrapper2">
                    <input
                        id = "email"
                        className="login-flip-card__input2"
                        name="email"
                        placeholder="Email utente"
                        type="text"
                        value={guestEmail}
                        onChange={(event) => {handleEmailChange(event)}}
                    />
                </div>
            </Col>
        </Row>
        <Row lg={6} className="justify-content-center">
                <Col className="mt-3 col-corsi justify-content-center" lg={3} sm={12}>
                    <button onClick={() => generateGuest()} className="prosegui-button">Genera</button>
                </Col>
                <Col className="mt-3 col-corsi justify-content-center" lg={3} sm={12}>
                    <button onClick={() => deleteGuest()} className="prosegui-button">Revoca i guest</button>
                </Col>
        </Row>

        </>

    );

}