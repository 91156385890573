import {useState} from "react";
import {Toast} from "react-bootstrap";

export const InterviewButton = (props) =>{
    const [show, setShow] = useState(false);

    const toggleShow = () => setShow(!show);

    return (
        <>
        <button onClick={toggleShow} className="cube-btn2 cube cube-hover" type="button">
            <div className="bg-top">
                <div className="bg-inner" />
            </div>
            <div className="bg-right">
                <div className="bg-inner" />
            </div>
            <div className="bg">
                <div className="bg-inner" />
            </div>
            <div className="text">{props.text}</div>
        </button>
            <Toast show={show} onClose={toggleShow}>
                <Toast.Header>
                    <strong className="me-auto">{props.text}</strong>
                </Toast.Header>
                <Toast.Body>
                    {props.content.messages.map((item, i) => {

                    if (i > 2) {
                        if(item.content.indexOf("CORRETTO") !== -1 && item.content.indexOf("DOMANDA") !== -1){
                            return (
                                <>✅ CORRETTO! <br/><br/>
                                    🤖 {item.content.substring(item.content.indexOf("DOMANDA"))}<br/><br/></>
                            );
                        } else if (item.content.indexOf("SBAGLIATO") !== -1 && item.content.indexOf("DOMANDA") !== -1) {
                            return (
                                <>❌ SBAGLIATO! <br/><br/>
                                    🤖 {item.content.substring(item.content.indexOf("DOMANDA"))}<br/><br/></>
                            );
                        }
                        else if (item.content.indexOf("SBAGLIATO") === -1 && item.content.indexOf("CORRETTO") === -1 && item.content.indexOf("DOMANDA") !== -1) {
                            return (
                                <>🤖 {item.content.substring(item.content.indexOf("DOMANDA"))}<br/><br/></>
                            );
                        }
                        else if (item.content.indexOf("SBAGLIATO") === -1 && item.content.indexOf("CORRETTO") === -1 && item.content.indexOf("DOMANDA") === -1) {
                            return (
                                <>💬 {item.content.substring(item.content.indexOf("DOMANDA"))}<br/><br/></>
                            );
                        }
                    }

                })}</Toast.Body>
            </Toast>
        </>
    );
}