export const ChallengeShell = (props) => {

    return (

                <div className="challenge-terminal-loader">
                    <div className="challenge-terminal-header">
                        <div className="challenge-terminal-title">{props.difficulty}</div>
                        <div className="challenge-terminal-controls">
                            <div className="challenge-control close" />
                            <div className="challenge-control minimize" />
                            <div className="challenge-control maximize" />
                        </div>
                    </div>
                    <div className="challenge-text">{props.text} Input: {props.input}</div>
                </div>
            );

}