import {useDispatch, useStore} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Header} from "../layout/Header";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {Footer} from "../layout/Footer";
import {DashboardButton} from "./widgets/DashboardButton";
import {setUsers} from "./reducers/chiSiamoSlicer";
import {useEffect, useState} from "react";
import axios from "../lib/axios";

export const Profile = () =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuVoice1 = "Board";
    const menuVoice2 = "Corsi";
    const menuVoice3 = "Tool AI";
    const menuVoice4 = "Challenge";
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [dasboardContent, setDashboardContent] = useState();

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});
        }

    }, []);
    return ( <>
        <Header></Header>
        <Container className="page-container" fluid>
            Profile page
        </Container>
        <Footer></Footer>
    </>);
}