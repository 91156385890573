import {Col, Row} from "react-bootstrap";
import axios from "../../lib/axios";
import {setUsers} from "../reducers/chiSiamoSlicer";
import {useDispatch, useStore} from "react-redux";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const CodeReview = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [languageId, setLanguageId] = useState("");
    const [content, setContent] = useState("");
    const [notes, setNotes] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [update, setUpdate] = useState((props.update) ? props.update : 0);

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});

        }

        getLanguageList();

    }, [usersReducer]);

    const handleLanguageIdChange = (e) =>{
        setLanguageId(e.target.value);
    }

    const handleContentChange = (e) =>{
        setContent(e.target.value);
    }

    const handleNotesChange = (e) =>{
        setNotes(e.target.value);
    }

    const switchCall = (update) => {

        if(update){
            updateCodeReview();
        }
        else{
            generateCodeReview();
        }
    }

    const getLanguageList = (event = null) =>{

        axios.get("/sanctum/csrf-cookie").then((response) => {

            axios.get("/get/language/list", {}).then((response) => {

                if(response.data.length !==0) {
                    setLanguageList(response.data.languages);
                }

            }).catch((error) => {
                if (error.response.status == 401) {

                    axios.post("/logout").then((response) => {
                        dispatch(setUsers({}));
                        navigate("/login");
                    }).catch((error)=>{});

                } else {
                    alert(error.response.data.message);
                }
            });

        });
    }

    const generateCodeReview = () =>{

        if(languageId !== "" && content !=="") {

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.post("/create/code/review", {
                    userId: users.userId,
                    languageId: languageId,
                    content : content,
                    notes : notes
                }).then((response) => {

                   if(response.data.esito === 1){
                       alert("Richiesta di code review inserita con successo");

                       setNotes("");
                       setContent("");
                       setLanguageId("");
                   }
                   else{
                       alert(response.data.message);
                   }

                }).catch((error) => {
                    if(error.response.status == 401){
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    }
                    else{
                        alert(error.response.data.message);
                    }
                });

            });
        }
        else{
            alert("Compila prima i dati necessari");
        }
    }

    const updateCodeReview = () =>{
        if(props.codeReviewId && content !=="") {

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.put("/update/code/review", {
                    userId: users.userId,
                    codeReviewId: props.codeReviewId,
                    content : content,
                    notes : notes
                }).then((response) => {

                    if(response.data.esito === 1){
                        props.setUpdateNeeded(props.actualUpdateValue+1);
                        alert("Review inviata con successo");

                        setNotes("");
                        setContent("");
                        setLanguageId("");
                    }
                    else{
                        alert(response.data.message);
                    }

                }).catch((error) => {
                    if(error.response.status == 401){
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    }
                    else{
                        alert(error.response.data.message);
                    }
                });

            });
        }
        else{
            alert("Compila prima i dati necessari");
        }
    }

    if(update){
        return (
            <>
                <Row lg={12} className="justify-content-center">
                    <Col lg={6} sm={12}>
                        <div className="login-wrapper_textarea">
                    <textarea
                        id="content"
                        className="login-flip-card__input_textarea"
                        name="content"
                        placeholder="Inserisci revisione"
                        onChange={(event) => {
                            handleContentChange(event)
                        }}
                        value={content}
                    />
                        </div>
                    </Col>
                </Row>
                <Row lg={12} className="justify-content-center">
                    <Col lg={6} sm={12}>
                        <div className="login-wrapper_textarea">
                <textarea
                    id="notes"
                    className="login-flip-card__input_textarea"
                    name="notes"
                    placeholder="Inserisci note"
                    onChange={(event) => {
                        handleNotesChange(event)
                    }}
                    value={notes}
                />
                        </div>
                    </Col>
                </Row>
                <Row lg={6} className="justify-content-center">
                    <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}>
                        <button onClick={() => switchCall(update)} className="prosegui-button">Invia</button>
                    </Col>
                </Row>
            </>

        );
    }
    else {
        return (
            <>
                <Row lg={12} className="justify-content-center">
                    <Col lg={6} sm={12}>
                        <div className="login-wrapper2">
                            <select
                                id="tech"
                                className="login-flip-card__input2"
                                name="tech"
                                onChange={(e) => handleLanguageIdChange(e)}
                                value={languageId}
                            >
                                <option value="" selected="true">Seleziona un linguaggio</option>
                                {languageList.map((item, i) => {
                                    return (<option value={item.id}>{item.description}</option>);
                                })}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row lg={12} className="justify-content-center">
                    <Col lg={6} sm={12}>
                        <div className="login-wrapper_textarea">
                    <textarea
                        id="content"
                        className="login-flip-card__input_textarea"
                        name="content"
                        placeholder="Inserisci codice da revisionare"
                        onChange={(event) => {
                            handleContentChange(event)
                        }}
                        value={content}
                    />
                        </div>
                    </Col>
                </Row>
                <Row lg={12} className="justify-content-center">
                    <Col lg={6} sm={12}>
                        <div className="login-wrapper_textarea">
                <textarea
                    id="notes"
                    className="login-flip-card__input_textarea"
                    name="notes"
                    placeholder="Inserisci note"
                    onChange={(event) => {
                        handleNotesChange(event)
                    }}
                    value={notes}
                />
                        </div>
                    </Col>
                </Row>
                <Row lg={6} className="justify-content-center">
                    <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}>
                        <button onClick={() => switchCall(update)} className="prosegui-button">Invia</button>
                    </Col>
                </Row>
            </>

        );
    }

}