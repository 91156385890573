import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import "./css/ChiSiamo.css";
import axios from "../lib/axios";
import {useEffect, useState} from "react";
import {useDispatch, useStore} from "react-redux";
import { useNavigate } from 'react-router-dom';
import {setUsers} from "./reducers/chiSiamoSlicer";
export const Login = () => {

    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emailRegister, setEmailRegister] = useState('');
    const [usernameRegister, setUsernameRegister] = useState('');
    const [passwordRegister, setPasswordRegister] = useState('');
    const [confirmPasswordRegister, setConfirmPasswordRegister] = useState('');
    const [role, setRole] = useState(2);

    useEffect( () => {

        if(users && users.logged == 1){
           navigate("/dashboard");
        }

    }, []);


    const login = (e, username, password) =>{

        e.preventDefault();

        if(username !== "" && password !== "") {

            axios.get("/sanctum/csrf-cookie").then((response) => {

                axios.post("/login", {
                    email: username,
                    password: password
                }).then((response) => {

                    if (response.data.logged) {
                        dispatch(setUsers(response.data));
                        navigate("/dashboard");
                    } else {

                        if(response.data.message){
                            alert(response.data.message);
                        }
                        else {
                            alert("Username o password errati.");
                        }
                    }
                }).catch((error) => {  alert(error.response.data.message); });

            });

        }
        else{
            alert("Inserisci tutti i dati necessari.");
        }
    }

    const signup = (e, username, password, confirmPassword, email) =>{
        e.preventDefault();

        if(username !== "" && password !== "" && confirmPassword !== "" && email !== "") {

            if (password === confirmPassword) {
                axios.get("/sanctum/csrf-cookie").then((response) => {

                    axios.post("/register", {
                        username: username,
                        password: password,
                        password_confirmation: confirmPassword,
                        email: email,
                        profileId:role,
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept' : 'application/json'
                        }
                    }).then((response) => {

                        if (response.data.esito) {

                            setUsernameRegister("");
                            setEmailRegister("");
                            setPasswordRegister("");
                            setConfirmPasswordRegister("");

                            alert("Registrato con successo.");
                        }
                        else{
                            alert(response.data.message);
                        }
                    }).catch((error) => {  alert(error.response.data.message); });


                });

            } else {
                alert("Le due password non coincidono.");
            }
        }
        else{
            alert("Inserisci tutti i dati necessari.");
        }
    }

    const handleChangeUsername = event => {
        setUsername(event.target.value)
    }

    const handleChangePassword = event => {
        setPassword(event.target.value);
    }

    const handleChangeEmailRegister = event => {
        setEmailRegister(event.target.value)
    }

    const handleChangePasswordRegister = event => {
        setPasswordRegister(event.target.value);
    }

    const handleChangeConfirmPasswordRegister = event => {
        setConfirmPasswordRegister(event.target.value);
    }

    const handleChangeUsernameRegister = event => {
        setUsernameRegister(event.target.value);
    }

    const handleRoleChange = event => {
        setRole(event.target.value);
    }
    return (
        <>
        <Header></Header>
            <Container className="page-container" fluid>
            <Row lg={12}>
                <Col lg={12} className="justify-content-center">
                    <div className="login-wrapper">
                        <div className="login-card-switch">
                            <label className="login-switch">
                                <input type="checkbox" className="login-toggle" />
                                <span className="login-slider" />
                                <span className="login-card-side" />
                                <div className="login-flip-card__inner">
                                    <div className="login-flip-card__front">
                                        <div className="login-title">Log in</div>
                                        <form className="login-flip-card__form" action="#">
                                            <input
                                                id = "login-email"
                                                className="login-flip-card__input"
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                onChange={handleChangeUsername}
                                            />
                                            <input
                                                id = "login-password"
                                                className="login-flip-card__input"
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                onChange={handleChangePassword}
                                            />
                                            <button onClick={(e) => login(e, username,password)} className="login-flip-card__btn">Entra</button>
                                        </form>
                                    </div>
                                    <div className="login-flip-card__back">
                                        <div className="login-title">Registrati</div>
                                        <form className="login-flip-card__form">
                                            <input
                                                id="email"
                                                className="login-flip-card__input"
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                onChange={handleChangeEmailRegister}
                                                value={emailRegister}
                                            />
                                            <input
                                                id="password"
                                                className="login-flip-card__input"
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                onChange={handleChangePasswordRegister}
                                                value={passwordRegister}
                                            />
                                            <input
                                                id="confirmPassword"
                                                className="login-flip-card__input"
                                                name="confirmPassword"
                                                placeholder="Conferma Password"
                                                type="password"
                                                onChange={handleChangeConfirmPasswordRegister}
                                                value={confirmPasswordRegister}
                                            />
                                            <input
                                                id="username"
                                                className="login-flip-card__input"
                                                name="username"
                                                placeholder="Inserisci username"
                                                type="text"
                                                onChange={handleChangeUsernameRegister}
                                                value={usernameRegister}
                                            />
                                            <div className="radio">
                                            <label htmlFor="radio-dev">
                                                DEV
                                             <input
                                                id="radio-dev"
                                                name="radio"
                                                checked="true"
                                                type="radio"
                                                value="2"
                                                onClick={handleRoleChange}
                                             />
                                            </label>
                                            </div>
                                            <div className="radio">
                                            <label htmlFor="radio-company">
                                                AZIENDA
                                                <input
                                                    id="radio-company"
                                                    name="radio"
                                                    type="radio"
                                                    value="3"
                                                    onClick={handleRoleChange}
                                                />
                                            </label>
                                            </div>
                                            <button onClick={(e) => signup(e, usernameRegister,passwordRegister, confirmPasswordRegister, emailRegister)} className="login-flip-card__btn">Conferma</button>
                                        </form>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </Col>
            </Row>
            </Container>
            <Footer></Footer>
        </>
    );
};
