import axios from "../../lib/axios";
import {setUsers} from "../reducers/chiSiamoSlicer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faSignIn} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useStore} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {profileList} from "../../config/profileList";

export const SideMenu = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users;

    const logout = () =>{
        axios.post("/logout").then((response) => {
            dispatch(setUsers({}));
            navigate("/login");
        }).catch((error)=>{});
    }

    if(users.profile !== profileList.GUEST) {
        return (
            <>
                <Link to={"/dashboard"} className="nav-link-devocracy"> Dashboard </Link>
                <Link to={"/interview"} className="nav-link-devocracy"> Colloqui </Link>
                <Link to={"/codeReviewList"} className="nav-link-devocracy"> CodeReviews </Link>
                <Link to={"/profile"} className="nav-link-devocracy"> Profilo </Link>
                <Link onClick={(event) => logout()} className="nav-link-devocracy">{users.userName} <FontAwesomeIcon
                    className="signin-icon" style={{color: "yellow"}} icon={faSignIn}/> Logout </Link>
            </>
        );
    }
    else{
        return (
            <>
                <Link onClick={(event) => logout()} className="nav-link-devocracy">
                    <FontAwesomeIcon
                    className="signin-icon" style={{color: "yellow"}} icon={faSignIn}/>Logout </Link>
            </>
        );
    }

}