import {useDispatch, useStore} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Header} from "../layout/Header";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {Footer} from "../layout/Footer";
import {DashboardButton} from "./widgets/DashboardButton";
import {setUsers} from "./reducers/chiSiamoSlicer";
import {useEffect, useState} from "react";
import {CorsiTab} from "./widgets/CorsiTab";
import {AiChat} from "./widgets/AiChat";
import {profileList} from "../config/profileList";
import {GuestChoice} from "./widgets/GuestChoice";
import axios from "../lib/axios";
import ReactDiffViewer from 'react-diff-viewer';
import {CodeReview} from "./widgets/CodeReview";
import {Challenge} from "./widgets/Challenge";

export const Dashboard = () =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [dasboardContent, setDashboardContent] = useState();

    const goToPage = (pageName) => {


        switch(pageName){

            case "corsi":
                setDashboardContent(<CorsiTab></CorsiTab>);
            break;
            case "ai":
                setDashboardContent(<AiChat></AiChat>);
                break;
            case "challenge":
                setDashboardContent(<Challenge></Challenge>)
            break;
            case "guestlinks":
                setDashboardContent(<GuestChoice></GuestChoice>)
            break;
            case "codereview":
                setDashboardContent(<CodeReview></CodeReview>)
        }
    }

    useEffect( () => {
        if(!users.logged){
            axios.post("/logout").then((response) => {
                dispatch(setUsers({}));
                navigate("/login");
            }).catch((error)=>{});
        }

        // i guest possono solo fare colloqui
        if(users.profile === profileList.GUEST){
            goToPage("ai");
        }
        else {
            goToPage("corsi");
        }
    }, []);


    if(users.profile === profileList.ADMIN || users.profile === profileList.COMPANY){
        return ( <>
            <Header></Header>
            <Container className="page-container" fluid>
                <Row lg={12}>
                    <Col lg={3} sm={12} className="text-center side-dashboard-menu">

                        <Row onClick={() => goToPage("corsi")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Corsi"></DashboardButton></Row>

                        <Row onClick={() => goToPage("ai")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Tech HR"></DashboardButton></Row>

                        <Row onClick={() => goToPage("challenge")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Challenge"></DashboardButton></Row>

                        <Row onClick={() => goToPage("guestlinks")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Guest User"></DashboardButton></Row>

                        <Row onClick={() => goToPage("codereview")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Code Review"></DashboardButton></Row>
                    </Col>
                    <Col lg={9} sm={12} className="text-center mt-5 side-dashboard-menu">
                        {dasboardContent}
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
        </>);
    }
    else if(users.profile === profileList.DEVELOPER){
            return ( <>
                <Header></Header>
                <Container className="page-container" fluid>
                    <Row lg={12}>
                        <Col lg={3} sm={12} className="text-center side-dashboard-menu">

                            <Row onClick={() => goToPage("corsi")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Corsi"></DashboardButton></Row>

                            <Row onClick={() => goToPage("ai")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Tech HR"></DashboardButton></Row>

                            <Row onClick={() => goToPage("challenge")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Challenge"></DashboardButton></Row>

                            <Row onClick={() => goToPage("codereview")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Code Review"></DashboardButton></Row>

                        </Col>
                        <Col lg={9} sm={12} className="text-center mt-5 side-dashboard-menu">
                            {dasboardContent}
                        </Col>
                    </Row>
                </Container>
                <Footer></Footer>
            </>);
    }
    else if(users.profile === profileList.GUEST){
        // i profili guest possono solo fare colloqui
        return ( <>
            <Header></Header>
            <Container className="page-container" fluid>
                <Row lg={12}>
                    <Col lg={3} sm={12} className="text-center side-dashboard-menu">
                        <Row onClick={() => goToPage("ai")} className="justify-content-center" lg={12} sm={12}><DashboardButton text="Tech HR"></DashboardButton></Row>
                    </Col>
                    <Col lg={9} sm={12} className="text-center mt-5 side-dashboard-menu">
                        {dasboardContent}
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
        </>);

    }
}