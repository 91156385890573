import {Col, Row} from "react-bootstrap";
import axios from "../../lib/axios";
import {useDispatch, useStore} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {BotMessage} from "./BotMessage";
import {setUsers} from "../reducers/chiSiamoSlicer";
import {profileList} from "../../config/profileList";

export const AiChat = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users ?? null;
    const [chatHistory, setChatHistory] = useState([]);
    const [interviewButtonText, setInterviewButtonText] = useState("Avvia colloquio");
    const [interviewStarted, setInterviewStarted] = useState(0);
    const [textDisabled, setTextDisabled] = useState(true);
    const [userMessage, setUserMessage] = useState("");
    const [chatPlaceholder, setChatPlaceholder] = useState("Scrivi un messaggio");
    const [tmpUsername, setTmpUsername] = useState(users.userName);
    const [tech, setTech] = useState("");

    const [candidateFieldDisabled, setCandidateFieldDisabled] = useState("");
    const [aiToken, setAiTokens] = useState(0);
    const startInterview = () =>{

        setTextDisabled(true);

        if(tech !== "" && tmpUsername !=="") {

            setCandidateFieldDisabled("disabled");

            axios.get("/sanctum/csrf-cookie").then((response) => {

                // cancello ogni conversazione precedente per l'utente
                deleteInterview();

                setChatHistory([]);
                setChatPlaceholder("Devocracy sta scrivendo, attendi...");

                axios.post("/start/interview", {
                    userId: users.userId,
                    tmpUserName: tmpUsername,
                    tech : tech
                }).then((response) => {

                    if (response.data.action === 4) {
                        setChatHistory([]);
                        setInterviewStarted(0);
                        setInterviewButtonText("Avvia colloquio");
                        setChatHistory([<BotMessage liclass="bot" pclass="bot-message-title" userSender="Devocracy BOT"
                                                    messageText="Numero di token insufficiente."></BotMessage>]);
                    } else {
                        setChatHistory([<BotMessage liclass="bot" pclass="bot-message-title" userSender="Devocracy BOT"
                                                    messageText={response.data.message}></BotMessage>]);
                        setInterviewStarted(1);
                        setInterviewButtonText("Invia Messaggio");
                        setTextDisabled(false);
                        setChatPlaceholder("Scrivi un messaggio");
                    }


                }).catch((error) => {
                    if (error.response.status == 401) {
                        axios.post("/logout").then((response) => {
                            dispatch(setUsers({}));
                            navigate("/login");
                        }).catch((error)=>{});
                    } else {
                        alert(error.response.data.message);
                    }
                });

            });
        }
        else{
            alert("Compila prima i dati del candidato");
        }
    }

    const continueInterview = () =>{

        if(userMessage === ""){
            alert("Inserisci un messaggio");
            return false;
        }

        axios.get("/sanctum/csrf-cookie").then((response) => {

            setChatHistory((prevState) => [...prevState, <BotMessage liclass="user" pclass="user-message-title" userSender={tmpUsername} messageText={userMessage}></BotMessage>]);

            document.querySelector(".chat-message-input").value="";
            setTextDisabled(true);
            setChatPlaceholder("Devocracy sta scrivendo, attendi...");

            axios.post("/continue/interview", {
                userId: users.userId,
                content: userMessage
            }).then((response) => {

                setChatHistory( (prevState) => [...prevState, <BotMessage liclass="bot" pclass="bot-message-title" userSender="Devocracy BOT" messageText={response.data.message}></BotMessage>]);

                setUserMessage("");

                if(response.data.action===1) {
                    setInterviewStarted(0);
                    setInterviewButtonText("Avvia colloquio");
                    setCandidateFieldDisabled("")
                }

                if(response.data.action===2) {
                    setChatHistory([]);
                    setInterviewStarted(0);
                    setInterviewButtonText("Avvia colloquio");
                    setChatHistory( (prevState) => [...prevState, <BotMessage liclass="bot" pclass="bot-message-title" userSender="Devocracy BOT" messageText="Nessun thread trovato, inizia un nuovo colloquio."></BotMessage>]);
                    setCandidateFieldDisabled("");
                }

                if(response.data.action===3) {
                    setChatHistory([]);
                    setInterviewStarted(0);
                    setInterviewButtonText("Avvia colloquio");
                    setChatHistory( (prevState) => [...prevState, <BotMessage liclass="bot" pclass="bot-message-title" userSender="Devocracy BOT" messageText="Limite di messaggi raggiungo, inizia un nuovo colloquio."></BotMessage>]);
                    setCandidateFieldDisabled("");
                }

                if(response.data.action===4) {
                    setChatHistory([]);
                    setInterviewStarted(0);
                    setInterviewButtonText("Avvia colloquio");
                    setChatHistory( (prevState) => [...prevState, <BotMessage liclass="bot" pclass="bot-message-title" userSender="Devocracy BOT" messageText="Numero di token insufficiente."></BotMessage>]);
                    setCandidateFieldDisabled("");
                }

                setTextDisabled(false);
                setChatPlaceholder("Scrivi un messaggio");



            }).catch((error) => {if(error.response.status == 401){
                axios.post("/logout").then((response) => {
                    dispatch(setUsers({}));
                    navigate("/login");
                }).catch((error)=>{});
            }
            else{
                alert(error.response.data.message);
            }
            });

        });
    }

    const deleteInterview = () =>{

        axios.get("/sanctum/csrf-cookie").then((response) => {

            axios.post("/delete/interview", {
                userId: users.userId
            }).then((response) => {

                setInterviewStarted(0);
                setInterviewButtonText("Avvia colloquio");

            }).catch((error) => {if(error.response.status == 401){
                axios.post("/logout").then((response) => {
                    dispatch(setUsers({}));
                    navigate("/login");
                }).catch((error)=>{});
            }
            else{
                alert(error.response.data.message);
            }
            });
        });
    }

    let getTokens = ()=>{
        axios.get("/sanctum/csrf-cookie").then((response) => {
            axios.get("/get/user/tokens").then((response) => {
                setAiTokens(response.data);

            }).catch((error) => {

                if(error.response.status == 401){
                    axios.post("/logout").then((response) => {
                        dispatch(setUsers({}));
                        navigate("/login");
                    }).catch((error)=>{});
                }
                else{
                    alert(error.response.data.message);
                }
            });
        });
    }

    useEffect( () => {

        getTokens();

        // se l'history viene svuotata a fine colloquio lo cancello dal db
        if(chatHistory.length === 0){
            deleteInterview();
        }

    }, [chatHistory]);

    let chatHistoryMap = chatHistory.map((item, i) => {
        return item;
    });

    let switchColloquio = () =>{

        if(interviewStarted){
            continueInterview();
        }
        else{
            startInterview();
        }
    }

    let handleInputChange = (e) =>{
      setUserMessage(e.target.value);
    }

    let handleTmpUsernameChange = (e) =>{
        setTmpUsername(e.target.value);
    }

    let handleTechChange = (e) =>{
        setTech(e.target.value);
    }

    return (
        <>
        <Row lg={12}>
                <Col className="justify-content-center" lg={6} sm={12}>
                    <div className="login-wrapper2">

                        <input
                            id = "tmpUsername"
                            className="login-flip-card__input2"
                            name="tmpUsername"
                            placeholder="Nome candidato"
                            type="text"
                            value = {tmpUsername}
                            disabled={candidateFieldDisabled}
                            onChange={(e) => handleTmpUsernameChange(e)}
                        />
                        <input
                            id = "tech"
                            className="login-flip-card__input2"
                            name="tech"
                            placeholder="Tecnologia e seniority"
                            type="text"
                            value = {tech}
                            disabled={candidateFieldDisabled}
                            onChange={(e) => handleTechChange(e)}
                        />

                    </div>
                    <div className="chat-card">
                        <div className="chat-chat-header">Devocracy Tech HR</div>
                        <div className="chat-chat-window">
                            <ul className="chat-message-list">
                                {chatHistoryMap}
                            </ul>
                        </div>
                        <div className="chat-chat-input">
                            <textarea disabled={textDisabled}
                                className="chat-message-input"
                                placeholder={chatPlaceholder}
                                onChange={(e)=>handleInputChange(e)}
                            />
                            <button onClick={() => switchColloquio()} className="chat-send-button">{interviewButtonText}</button>
                        </div>
                    </div>
                </Col>
        </Row>
        </>

    );

}