import logo from '../assets/images/devocracy.png'; // with import
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faSignIn} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {SideMenu} from "../pages/widgets/SideMenu";


export const Header = () =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loginText, setLoginText] = useState();
    const [menuText, setMenuText] = useState();

    const actualStore = useStore().getState();
    const usersReducer = actualStore.userSliceReducer;
    const users = actualStore.userSliceReducer.users;

    useEffect( () => {

        if(users.logged === 1){
            setLoginText(<Nav className="me-auto"><SideMenu></SideMenu></Nav>)
            setMenuText();
        }
        else{
            setLoginText(<Link to={"/login"} className="nav-link-devocracy"><FontAwesomeIcon className="signin-icon" style={{color:"yellow"}} icon={faSignIn}/> Area Riservata</Link>);
            setMenuText( <Nav className="me-auto">
                              <Link to={"/"} className="nav-link-devocracy"> Home </Link>
                         </Nav>
            );
        }

    }, [usersReducer]);

    return (
        <>
            <Navbar className="header" expand="lg">
                <Container>
                    <Navbar.Brand><img alt="logo" src={logo} width="180px"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor:"yellow"}} />
                    <Navbar.Collapse style={{backgroundColor:"black", fontWeight:"bold"}} id="basic-navbar-nav">
                        {menuText}
                        {loginText}
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )
}