import {Col, Row} from "react-bootstrap";
import {CorsoTab} from "./CorsoTab";

export const CorsiTab = () => {

    return (
        <>
        <Row lg={12}>
                <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}><CorsoTab courseTitle="Backend Roadmap" courseDescription="Il percorso per diventare il top come backend developer."></CorsoTab></Col>
                <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}><CorsoTab courseTitle="Frontend Roadmap" courseDescription="Il percorso per diventare front end developer include una formazione di alto livello su tutto ciò che concerne la UX e il mondo di Javascript."></CorsoTab></Col>
        </Row>
        <Row lg={12}>
            <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}><CorsoTab courseTitle="Devops roadmap" courseDescription="Se il mondo del cloud, la containerizzazione e la possibilità di creare potenti pipeline per migliorare il deploy di applicazioni ti interessa, questo è il percorso giusto."></CorsoTab></Col>
            <Col className="mt-3 col-corsi justify-content-center" lg={6} sm={12}><CorsoTab courseTitle="Soft skills & Project management Roadmap" courseDescription="Questo percorso è adatto a tutti, esperti e meno esperti, per potenziare le proprie soft skills."></CorsoTab></Col>
        </Row>
        </>

    );

}